// Only used for production builds
const { Elm } = require('@mymedsandme/m3-elm/dist/Classic.cjs')

'use strict';
/* global JS_AIRBRAKE_KEY, JS_AIRBRAKE_ID */

const AirbrakeClient = require('airbrake-js');

const airbrake = new AirbrakeClient({
    projectId: JS_AIRBRAKE_ID,
    projectKey: JS_AIRBRAKE_KEY
});

airbrake.call(boot);

function boot () {
    window.MMM = window.MMM || {};
    window.MMM.Elm = Elm.Classic

}
